<template>
  <div class="support-container">
    <SectionTitle title="Liên hệ" link="/" />
    <div class="support">
      <div
        v-for="item in SUPPORT_MENU"
        :id="`support-${item?.name}`"
        :key="item.name"
        class="support__item"
        :class="[item?.type, item?.className]"
      >
        <div>
          <NuxtLink
            :to="item?.url"
            target="_blank"
            class="support__item--link"
          >
            <CommonBaseImg
              v-if="!isBotChromeLighthouse"
              class="icon"
              :src="item.icon"
              :alt="item.name"
            />
            <p class="text">{{ item.name }}</p>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useContact } from '~/composables/useContact'
import { useAppStore } from '~/store/app'
import { getFooterContact } from '@/constants/footer'
import { seoApi } from '~/api/seo'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))
const SectionTitle = defineAsyncComponent(() => import('@/components/common/title-section.vue'))
const { isBotChromeLighthouse } = useAppStore()
const { SUPPORT_MENU } = getFooterContact()
const { fetchContact } = useContact()

useAsyncData(() => {
  setTimeout(async () => {
    await fetchContact()
  }, 200)
})

</script>
<style lang="scss" scoped src="assets/scss/components/mobile/pages/home/section-contact/index.scss" />
